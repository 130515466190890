<template>
    <div class="candidate-details-tab__body">
        <div class="details__card gutter-10" v-if="candidate.cover_letter">
            <div class="details__card__head">
                <h4>{{ $t("Cover Letter") }}</h4>
            </div>
            <div class="details__card__body text-break">
                <template v-if="shortCoverLater.length < candidate.cover_letter.length && showMore">
                    <div class="details__text__pre" v-text="shortCoverLater">
                    </div>
                    <div class="text-right">
                        <a href="javascript:void(0)" @click="showFullCoverLatter" style="color: #597dfc;">...{{ $t('Show more') }}</a>
                    </div>
                </template>
                <div v-else class="details__text__pre" v-html="candidate.cover_letter"></div>
            </div>
        </div>
        <div class="details__card gutter-10" v-if="candidate.custom_fields.length > 0">
            <div class="details__card__head">
                <h4 class="text-capitalize">{{ $t("Custom Queries") }}</h4>
            </div>
            <div class="details__card__body">
                <ul class="info__list">
                    <li v-for="cf in candidate.custom_fields"
                        class="label__full  label__full--primary list-item--primary">
                        <p><span class="text-primary">{{ cf.title }}</span>: {{ cf.value }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="details__card gutter-10">
            <div class="details__card__head">
                <h4 class="text-capitalize">{{ $t("Experience") }}</h4>
                <p class="user__experience__label">
                    <span id="user__experience__label">{{ $t("Total Year of Experience") }}</span>:
                    <label class="user__experience__label__info">(<span id="user__experience__point">{{ candidate.total_experience }}</span> <span id="user__experience__point__label">{{ $t("years") }}</span>)</label>
                </p>
            </div>
            <div class="details__card__body">
                <ul class="info__list" v-if="employments.length">
                    <li v-for="(exp, index) in employments"
                        class="label__full  label__full--primary list-item--primary">
                        <p class="mb-2 candidate__experience" v-html="getExperienceDate(exp)"></p>
                        <p class="label__content d-flex justify-content-between">
                            <span>{{ exp.company_name }}, {{ exp.department_name }}</span>
                            <a href="javascript:void(0)" style="color: #597dfc;" v-if="exp.show && !exp.showMore" @click="toggleResponsibility(index)">...{{ $t('more') }}</a>
                        </p>
                        <p class="my-2 text-justify" v-if="exp.showMore">{{ $t('Responsibilities') }}: <br>
                            <span class="label__full--eraseble">{{ exp.responsibilities }}</span>

                            <a href="javascript:void(0)" style="color: #597dfc;" @click="toggleResponsibility(index)">...{{ $t('hide') }}</a>

                        </p>
                    </li>
                </ul>
                <ul class="info__list" v-else>
                    <li class="label__full  label__full--primary list-item--primary">
                        <p class="mb-2">{{ $t('No job experience') }}</p>
                    </li>
                </ul>

            </div>
        </div>
        <div class="details__card gutter-10" v-if="candidate.user.educations.length">
            <div class="details__card__head">
                <h4 class="text-capitalize">{{ $t("Educational Qualification") }}</h4>
            </div>
            <div class="details__card__body">
                <ul class="info__list">
                    <li v-for="edu in candidate.user.educations"
                        class="label__full  label__full--primary list-item--primary">
                        <p class="mb-2">{{ edu.degree_name }}</p>
                        <p class="mb-2"><span>{{ edu.level_name }}</span></p>
                        <p class="label__content academic__content"><span class="academic__name">{{ edu.academy_name }}</span> (<span id="passing__year">{{ edu.passing_year }}</span>)</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { dateConvertSpan } from '../../../extra/helper';

export default {
    props: ['candidate'],
    data() {
        return {
            showMore: true,
            showMoreExperience: false,
            employments: [],
        }
    },
    computed: {
        shortCoverLater() {
            if (this.candidate.cover_letter.length < 300) {
                return this.candidate.cover_letter;
            }

            return this.strip(this.candidate.cover_letter).slice(0, 300);
        },
    },
    methods: {
        getExperienceDate(exp) {
            return exp.designation + "("+ this.dateConvertToSpan(exp.from) + " - " +(exp.to === null ? 'Present' : this.dateConvertToSpan(exp.to)) + ")";
        },
        dateConvertToSpan(date) {
            return dateConvertSpan(date);
        },
        showFullCoverLatter() {
            this.showMore = false;
        },
        showFullExperience() {
            this.showMoreExperience = false;
        },
        strip(html) {
            let doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        },
        toggleResponsibility(index) {
            this.employments[index].showMore = !this.employments[index].showMore;
        },
    },
    mounted() {
        this.employments = this.candidate.user.employments.map(e=> {
            return {
                ...e,
                show: e.responsibilities && e.responsibilities.length > 0,
                showMore: false
            }
        });
    }
}
</script>

<style scoped>


.academic__content {
    color: #7d8091;
}

.academic__name {
    color: #597dfc;
}

.details__card .details__card__head p.user__experience__label{
    color: #7d8091;
}

p.user__experience__label span {
    color: inherit !important;
}

.user__experience__label .user__experience__label__info {
    color: #597dfc !important;
}

.label__full--eraseble {
    white-space: pre-line;
}

@media all and (max-width: 599px) {
    .details__card__body .info__list .label__full p span {
        word-break: break-all;
    }
}

.page-body .content-area__body .details__card__body .details__text__pre p {
    font-size: 16px !important;
    font-weight: 500 !important;
}
.page-body .content-area__body .details__card__body .details__text__pre p span {
    color: #212529 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}


@media all and (max-width: 767px) {
    .page-body .content-area__body .details__card__body .info__list li.label__full {
        padding-left: 25px;
    }
    .page-body .content-area__body .details__card__body .info__list li.label__full:before {
        left: 10px;
    }
}

</style>

<style>
    .label__full--primary .candidate__experience span {
        color: #212529 !important;
    }
</style>
